import React from 'react';
export const Banner00DataSource = {
  wrapper: { className: 'banner0 kgs0uhdm3nm-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper kgs2zmrcdzq-editor_css' },
  title: {
    className: 'banner0-title kgs7qnaw3ol-editor_css',
    children: (
      <span>
        <p>
          Healthville Murders<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content kgduq95viw-editor_css',
    children: (
      <span>
        <span>
          <p>
            Get fit together with a social murder mystery game<br />
          </p>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button kgs36tb7e6o-editor_css',
    children: 'Learn More',
    type: 'default',
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: 'https://i.imgur.com/Sc19bGz.gif' },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>
                A killer is on the loose and killing your towns folk<br />
              </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content kgs8e11xeg-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <p>
                                                      • 6 towns folk, 1 killer
                                                      among you<br />
                                                    </p>
                                                    <p>
                                                      • You have 7 days to
                                                      figure it out before you
                                                      are all murdered<br />
                                                    </p>
                                                    <p>
                                                      • Don't forget to meet
                                                      your daily fitness
                                                      challenges or you're dead<br />
                                                    </p>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature12DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: 'https://i.imgur.com/UL4nTsK.gif' },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>
          Work together to find the murderer<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'content1-content kjz5gaql5-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>
                  • The murderer is also looting your safety deposits and time
                  is not on your side<br />
                </p>
                <p>
                  • Chat among yourselves via text to solve who the murderer is<br />
                </p>
                <p>
                  • You lose if you're all murdered or the murderer gets away
                  with most of your loot<br />
                </p>
                <p>
                  • If you're the murderer, then win $1.25 to get away with the murders<br />
                </p>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature23DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: 'https://i.imgur.com/KX9BEFj.png' },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>
            A fitness game master will keep you in check<br />
          </p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content kjz5ktzoqa-editor_css',
    children: (
      <span>
        <p>
          • Your game master creates personalized daily challenges to help you
          achieve your goals and guides your mystery game
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: 'https://i.imgur.com/HYoqQsr.png' },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <p>
                    A food logger and meal planner will keep your diet on track<br />
                  </p>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content khcjdw00icc-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>• Track fat, carbs, protein, calories and much more</p>
                      <p>
                        • Get planned meals with your preferred diet to meet your
                        goals<br />
                      </p>
                      <p>
                        • Go grocery shopping with a ready to go ingredient list to help prepare meals in
                        advance<br />
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature22DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: 'https://i.imgur.com/psdnHWs.png' },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>Monitor progress of your goals over time</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content khcjm0ewkxd-editor_css',
    children: (
      <span>
        <p>• Beautiful charts from logged exercise routines to help you monitor your progress</p>
        <p>
          • Your coach can track your charts and provide insight when needed
        </p>
      </span>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Health Goals</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://i.imgur.com/bDSEnBr.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>Lose Weight</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://i.imgur.com/sM5X4pI.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                    Reduce Heart Disease<br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item kgs6hr8yrsh-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kgs6f8x7kvs-editor_css',
              children: 'https://i.imgur.com/YfaZJCT.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                    Become Stronger<br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kgs6r9zgczc',
        className: 'content0-block kgs75c7lf3l-editor_css',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item kgs6hr8yrsh-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kgs6f8x7kvs-editor_css',
              children: 'https://i.imgur.com/K6XxXD7.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                    Reduce Stress, Anxiety and Depression<br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Pricing</p>
            <p>
              <br />
            </p>
          </span>
        ),
        className: 'pricing1-title-h1',
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: {
            className: 'pricing1-top-wrapper kgs944vwpuk-editor_css',
          },
          name: { className: 'pricing1-name', children: 'Starter' },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            $6.25 / week<br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content kjz3pfrue8l-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                1 dedicated certified trainer and game master<br />
                              </p>
                              <p>
                                7 days of murder mystery game with other members<br />
                              </p>
                              <p>Goal based personalized plan</p>
                              <p>
                                Meal planner and food logger <br />
                              </p>
                              <p>
                                Make new friends or play with existing ones<br />
                              </p>
                              <p>
                                No contract<br />
                              </p>
                              <p>
                                Cancel anytime<br />
                              </p>
                              <p>
                              100% refund if not satisfied<br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button kgs94sr8d4q-editor_css',
                href: '#',
                children: (
                  <span>
                    <p>Click on chat for more info</p>
                  </span>
                ),
              },
            },
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright kgs956u90i-editor_css',
    children: (
      <span>
        <span>
          <span>©2020 Tangerine Health All Rights Reserved</span>
        </span>
      </span>
    ),
  },
};
